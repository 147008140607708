import Particles from 'react-tsparticles';

const options = {
  fpsLimit: 40,
  fullScreen: {
    enable: false
  },
  particles: {
    number: {
      value: 200,
      density: {
        enable: true,
        area: 800,
      },
    },
    color: {
      value: ["#fdcf58", "#757676", "#f27d0c", "#800909", "#f07f13"],
    },
    opacity: {
      value: { min: 0, max: 0.3 },
    },
    size: {
      value: { min: 3, max: 6 },
    },
    shape: {
      type: 'square'
    },
    move: {
      enable: true,
      speed: 1,
      random: false,
      direction: 'top'
    },
  }
}

function HeroParticles() {
  return <Particles options={options} />
}

export default HeroParticles;
