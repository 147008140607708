import './App.css';
import HeroParticles from './HeroParticles';

function App() {
  return (
    <div className="app">
      <div className="navigation">
        <a href="/"><div className="logo" /></a>
        <div className="menu">
          <a href="#story" className="menu-item">Story</a>
          <a href="#blazers" className="menu-item">Blazers</a>
          <a href="#roadmap" className="menu-item">Roadmap</a>
          <a href="https://discord.gg/jAB9NfakAW" className="menu-item" target="_blank" rel="noreferrer"><div className="discord-icon" /></a>
          <a href="https://twitter.com/dungeonblazer" className="menu-item" target="_blank" rel="noreferrer"><div className="twitter-icon" /></a>
        </div>
        <div className="menu-mobile">
          <div className="menu-item">•••</div>
        </div>
      </div>
      <div className="hero">
        <HeroParticles />
        <div className="shadow"></div>
        <div className="marquee-wrapper rainbow-background">
          <div className="marquee">
            <span>SEEK THE RELIC WITHIN... MINT SOON... SEEK THE RELIC WITHIN... MINT SOON... SEEK THE RELIC WITHIN... MINT SOON... SEEK THE RELIC WITHIN... MINT SOON... SEEK THE RELIC WITHIN... MINT SOON... SEEK THE RELIC WITHIN... MINT SOON... </span>
            <span>SEEK THE RELIC WITHIN... MINT SOON... SEEK THE RELIC WITHIN... MINT SOON... SEEK THE RELIC WITHIN... MINT SOON... SEEK THE RELIC WITHIN... MINT SOON... SEEK THE RELIC WITHIN... MINT SOON... SEEK THE RELIC WITHIN... MINT SOON... </span>
          </div>
        </div>
      </div>
      <div className="content after-hero" id="story">
        <h1><span className="capital-letter mega">W</span>ithin a land ravaged by darkness, there is light found in distant legend... <br/> in <span className="rainbow">SACRED RELICS</span></h1>
        <p>Sealed away in <span className="">THE LOST DUNGEON</span>, hordes of monsters still guard them, bound by the will of the ageless KEEPER.</p>
        <p>Now, it's up to you to bring light to the land once more and take up the legendary title of <span className="highlight">DUNGEON BLAZER</span>!</p>
      </div>
      <div className="background-corridor-container">
        <div className="background-corridor"></div>
      </div>
      <div className="content" id="blazers" style={{'margin-top': '-5rem'}}>
        <p><span className="capital-letter">G</span>et ready for a perilous quest to fight back darkness and find <span className="rainbow">SACRED RELICS</span>, in this throwback crypto side-scroller.</p>
      </div>
      <div className="mint-box">
        <div className="characters">
          <div className="character">
            <div className="character-image" />
          </div>
        </div>
        <div className="mint-button-container">
          <div className="gold">JOIN THE BLAZER GUILD!</div>
          <p className="gold" style={{opacity: 0.5, margin: 0, textAlign: 'center'}}>0 / 10,000 members</p>
          <div className="button mint">Mint soon&#8482;</div>
        </div>
      </div>
      <div className="content">
        <p><span className="capital-letter">S</span>tart by becoming a member of the BLAZER GUILD, a collective of 10,000 generative characters, from regions with vastly different items and attributes.</p>
        <p>Each BLAZER is represented by a NFT on the blockchain and is also used as your ticket to play the game. Ready to seek the relic within?</p>
        <div className="button-group">
          <a href="https://discord.gg/jAB9NfakAW" className="button social rainbow-background-weird" style={{marginRight: '2rem'}} target="_blank" rel="noreferrer">Join the Discord &gt;</a>
          <a href="https://twitter.com/dungeonblazer" className="button social rainbow-background-reverse" target="_blank" rel="noreferrer">Follow on Twitter &gt;</a>
        </div>
      </div>
      <div className="content">
        <hr id="roadmap" />
        <h1 className="blue">ROADMAP</h1>
        <ul className="roadmap">
          <li><span className="blue">33% MINT</span> Discover the story and secrets of the BLAZER GUILD within <span className="place">THE LIBRARY</span>, the first playable location in the game. Play as one of five BLAZERS that correlates with your NFT. Development starts when the goal is met.</li>
          <li><span className="blue">66% MINT</span> Within the capitol city of the BLAZER GUILD explore <span className="place">THE SQUARE</span>, the second playable location in the game. Play as one of five BLAZERS that correlates with your NFT. Development starts when the goal is met.</li>
          <li><span className="blue">100% MINT</span> Set out on a perilous quest through THE LOST DUNGEON to vanquish the ageless KEEPER and experience the unfolding story of DUNGEON BLAZER in the first playable chapter of this throwback crypto side-scroller. Play as one of five BLAZERS that correlates with your NFT. Development starts when the goal is met.</li>
          <li><span className="blue">BEYOND</span>  The team is tremendously excited to develop THE ROADMAP'S goals as we take feedback from the community and explore a world that we've truly fallen in love with. We're dedicated to giving this project our all along the way. </li>
        </ul>
      </div>
      <div className="content legal">
        <hr />
        <div className="logo-mark" />
        <p>Created by your friends at Enok Games, inspired by lonelybird</p>
        <p>© 2022 Enok Games, LLC • terms and conditions</p>
      </div>
      <div className='background-rocks' />
    </div>
  );
}

export default App;
